import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import Main from './Main';
import Trades from './Hedge';
import HedgePercent from './HedgePercent';
export default function App() {
  return (
      <div>
        <HedgePercent />  
      </div>
  );
}
